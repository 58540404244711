export default {
  namespaced: true,
  state: {
    snackbars: [],
    stepper_id: 1,
    builder_id: null,
    project_id: null,
    manager_id: null,
    promoter_id: null,
  },
  getters: {
    getStepperId: (state) => state.stepper_id,
    getBuilderId: (state) => state.builder_id,
    getProjectId: (state) => state.project_id,
    getPromoterId: (state) => state.promoter_id,
    getManagerId: (state) => state.manager_id,
    getLedgerId: (state) => state.ledger_id,
    getPaymentId: (state) => state.payment_id,
  },
  mutations: {
    SET_SNACKBAR(state, snackbar) {
      state.snackbars = state.snackbars.concat(snackbar);
    },
    SET_STEPPER_ID(state, value) {
      state.stepper_id = value
    },
    SET_BUILDER_ID(state, value) {
      state.builder_id = value
    },
    SET_PROJECT_ID(state, value) {
      state.project_id = value
    },
    SET_LEDGER_ID(state, value) {
      state.ledger_id = value
    },
    SET_PAYMENT_ID(state, value) {
      state.payment_id = value
    },
  },
  actions: {
    showSnackbar({ commit }, snackbar) {
      snackbar.showing = true;
      snackbar.color = snackbar.color || "success";
      commit("SET_SNACKBAR", snackbar);
    },
    setBuilderId({ commit }, value) {
      commit("SET_BUILDER_Id", value);
    },
    setPaymentId({ commit }, value) {
      commit("SET_PAYMENT_ID", value)
    },
    setLedgerId({ commit }, value) {
      commit("SET_LEDGER_ID", value)
    },
  },
};
