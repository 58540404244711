<template>
  <div class="AuthLayoutContainer d-flex flex-column align-center justify-center gap-4">
    <!-- <div class="d-flex justify-center"> -->
       <!-- <img
       src="@/assets/building stru 1.png"
       height="550px"
       width="550px"
       class="pa-1 img mx-1 "
       />  -->
    <!-- <div class="cardContent text-center"> -->
      <img
        src="@/assets/logo.svg"
        height="104px"
        width="423px"
        class="my-4" v-if="$route.name !== 'companydetails'"
      />
      <!-- <div class="grey--text font-weight-bold fs-16 mt-1">Prop Technologies Private Limited</div> -->
      <!-- <br /> -->
      <!-- <h2 class="black--text font-weight-bold font-family-roboto mt-6" v-if="$route.name =='verifyOtp'">Loan Vendor Portal</h2>  -->
      <!-- <h3 class="black--text font-weight-bold font-family-roboto pa-2">Loan Vendor Portal</h3> -->
      <!-- <v-card class="elevation-2 pa-5 data-card mt-2" v-if="$route.name !== 'companydetails'"> -->
        <router-view></router-view>
      <!-- </v-card> -->
      <!-- <div  v-if="$route.name =='companydetails'"> -->
      <!-- <router-view></router-view> -->
      <!-- </div> -->
    <!-- </div> -->
    <!-- </div> -->
  </div>
</template>
<script>
export default {
  name: "AuthLayout",
  data() {
    return {};
  },
};
</script>
<style scoped>
.box {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
.authContent {
  min-height: 50vh;
  display: flex;
  justify-content: center;
}
.AuthLayoutContainer {
  margin-top: -8px;
  width: 75%;
  margin-inline: auto;
  min-height: 100vh;
  background-color: white;
  background-image: url('@/assets/building stru 1.png');
  background-size: cover;
  background-attachment: fixed;
}
.img {
  align-self: center;
  color: #d4d5d6;
}
.cardContent {
  align-self: center;
  margin-left: 0%;
  width: 300px;
  padding: 4px;
  position: absolute;
  top: 8%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  -ms-transform: translateX(5%);
  transform: translateX(5%);
}
.data-card {
  min-height: 300px;
  padding: 4px;
  /* margin-bottom: -50px; */
  border-radius: 14px !important;
}
.data-card_1 {
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 345px;
  max-width: 350px;
  padding: 0px;
  border-radius: 5px !important;
}
</style>
<style>
@media only screen and (max-width: 480px) {
  .cardContent {
    max-height: 330px;
    width: 360px !important;

    /* min-height: 400px !important; */
  }
}
/* @media only screen and (max-height: 1250px)
{
    .cardcontent{
        width: 500px !important;
    }
} */
</style>
