<template>
<v-app>
  <v-app-bar class="white pr-5 pl-2" height="45px" app elevation="1">
    <v-app-bar-nav-icon
      v-if="$vuetify.breakpoint.xsOnly"
      @click="drawer = !drawer"
    >
    
  </v-app-bar-nav-icon>
    
  <v-btn v-if="$route.params.id" small icon  @click="$router.go(-1)">
  <v-icon 
    >mdi-chevron-left</v-icon
  >
</v-btn>
      <h4>{{ $route.meta.title }}</h4>

    <v-spacer />
    <!-- <v-avatar
      class="primary white--text capitalize elevation-3"
      size="35"
      v-if="user_detail.name"
      ><span class="text-center">YD</span></v-avatar> -->
    <v-avatar
      color="grey white--text Capitalize elevation-4"
      size="30"
      v-if="user_detail.builder_name"
    >
      <span class="text-center text-capitalize">{{
        user_detail.builder_name.charAt(0)
      }}</span>
    </v-avatar>
    <!-- >{{ user_detail.name.charAt(0) }} -->
    <span class="ml-3 capitalize">{{ user_detail.builder_name }}</span>
    <v-menu offset-y>
      <template v-slot:activator="{ on, attrs }">
        <v-btn icon dark v-bind="attrs" v-on="on">
          <v-icon class="black--text pl-n6">mdi-chevron-down</v-icon>
        </v-btn>
      </template>
      <div class="handler">
        <div class="text-center white">
          <v-row>
            <v-col cols="9" md="9" class="ml-n4">
              <h4 class="mt-3 mb-3 pl-4">Available Credit</h4>
            </v-col>
            <v-col cols="3" md="3" class="mt-3 ml-n4">
              <p class="content">{{ builderCredit}}</p>
            </v-col>
            <v-col cols="2" md="2" class="mt-4 ml-n6">
              <v-img src="@/assets/credits.svg"></v-img>
            </v-col>
          </v-row>
          <v-divider class="" />
          <v-divider class="" />
          <div class="pa-2">
            <div
              class="register-btn pointer px-15 py-2 rounded-pill text-center"
              @click="registerHandler"
              >Profile</div
            >
          </div>
        </div>
        <v-divider class="" />
        <div class="pa-2">
          <div
            class="logout-btn pointer px-15 py-2 rounded-pill text-center"
            @click="logoutHandler"
            >Logout</div
          >
        </div>
        <v-divider class="" />
        <div class="pa-2">
          <div
            class="logout-btn pointer px-15 py-2 rounded-pill text-center"
            @click="$router.push({name:'resetpassword'})"
            >Reset Password</div
          >
        </div>
      </div>
    </v-menu>
  </v-app-bar>
  <v-main class="pa-3" style="background-color: #edf2f4; min-height: 100vh">
    <router-view
    />
  </v-main>
  <v-navigation-drawer
    v-model="drawer"
    :permanent="$vuetify.breakpoint.smAndUp"
    :temporary="$vuetify.breakpoint.xsOnly"
    app
    class="elevation-1 py-5 d-flex flex-column justify-space-between"
    height="100%"
    width="230px"
  >
    <v-list-item>
      <v-list-item-content>
        <v-list-item-title class="text-center">
          <img src="@/assets/logo.svg" height="75px" width="180px" />
        </v-list-item-title>
      </v-list-item-content>
    </v-list-item>
    <!-- <v-divider class="grey" /> -->
      <v-list dense nav class="pl-0 pr-0 py-0">
        <v-list-item
          v-for="item in navLinks"
          :key="item.name"
          :to="item.to"
          link
          nav
          itemtype="text-rounded"
          class="active-font rounded-0"
          active-class="primary white--text rounded-0 elevation-2"
        >
          <v-list-item-content class="px-8 rounded-0">
            <v-list-item-title>{{ item.name }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
      <!-- <template v-slot:append>
        <v-list-item href="mailto:ejaz.ahmed@codenicely.in" 
        prepend-icon="mdi-face-agent"
        link
          nav
          value="email"
          color="primary"
          class="active-font rounded-0"
          active-class="primary white--text rounded-0 elevation-2"
        >
        <v-list-item-icon>
          <v-icon >mdi-face-agent</v-icon>
        </v-list-item-icon>
            <v-list-item-title> ejaz.ahmed@codenicely.in</v-list-item-title> 
          </v-list-item>
      </template> -->
    
  </v-navigation-drawer>
  
</v-app>

</template>
<script>
// import { mapGetters } from "vuex";
import authToken from "@/auth/authToken";
export default {
  name: "AppLayout",
  data() {
    return {
      url: "",
      drawer: false,
      user_detail: {},
      builder_credit: "",
      ledgerData: [],
      page_count: 0,
      search: "",

      navLinks: [
        {
          name: "Projects",
          icon: "",
          to: "/",
        },

        {
          name: "Project Lead",
          icon: "",
          to: "/lead",
        },
        {
          name: "Transactions",
          icon: "",
          to: "/transaction",
        },
        {
          name: "Support",
          icon: "",
          to: "/support",
        },
        {
          name: "Masters Management",
          icon: "",
          to: "/master",
        },
        {
          name: "Credit Ledger",
          icon: "",
          to: "/ledger",
        },
      ],
    };
  },
  computed: {
  
     builderCredit() {
      return this.$store.state.ledger.builderCredit;
    },
  },
  beforeCreate() {
    const successHandler = (res) => {
      this.user_detail = res.data;
      // console.log("app lyout   ");
    };
    return this.$request(this.$keys.GET, this.$urls.get_builder_info, {
      onSuccess: successHandler,
      isTokenRequired: true,
    });
  },
  mounted() {
    
     this.getLedger();
    },
  methods: {
    logoutHandler() {
      authToken.removeToken();
      this.$router.push({ name: "login" });
    },
    registerHandler() {
      // const successHandler = () => {
      this.$router.push({ name: "companydetails" });
      // };
      // return this.$request(this.$keys.GET, this.$urls.get_builder_info , {
      //   onSuccess: successHandler,
      //   isTokenRequired: true,
      // });
    },
    
    getLedger() {
        this.loading = true;
         const successHandler = (res) => {
        //  console.log("hgjhj",res.data.builder_credit);
        // this.builder_credit = res.data.builder_credit
        this.$store.commit('ledger/SET_BUILDER_CREDIT',res.data.builder_credit)
        this.loading = false;
      };
      const finallyHandler = () => {
        this.loading = false;
      };
      return this.$request(
        this.$keys.GET,
        this.$urls.builder_credit.get_builder_credit,
        {
          // params: formData,
          onSuccess: successHandler,
          onFinally: finallyHandler,
          isTokenRequired: true,
          // cancle:true,
        }
      );
      },

  },
};
</script>
<style scoped>
.nav-drawer {
  width: 15px;
}
.active-font {
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
}
.register-btn:hover {
  background: #e3e3e3;
  padding-left: -12%;
}
.logout-btn:hover {
  background: #e3e3e3;
  padding-left: -12%;
}
.color-white {
  color: white;
}
.content {
  background: linear-gradient(62deg, #f7ba2c 0%, #fb8142 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.image-class {
  fill: #ed6866;
}
.handler {
  background-color: white;
}
</style>
