<template>  
<v-dialog v-model="loadingScreen">
    <v-overlay :value="loadingScreen">
      <v-progress-circular
        indeterminate
        size="64"
      ></v-progress-circular>
    </v-overlay>
</v-dialog>
  </template>

<script>
export default {
   computed:{
    loadingScreen(){
        return this.$store.state.app.loading_screen
    }
   }
}
</script>

<style lang="scss" scoped>

</style>