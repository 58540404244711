<template>
  <v-app>
    <v-main>
      <snack-bar />
      <LoadingScreen/>
      <ConfirmationDialog/>
      <component :is="this.$route.meta.layout || 'div'">
        <router-view/>
       </component>
    </v-main>
  </v-app>
</template>
<!-- /* eslint-disable */ -->
<script>
import ConfirmationDialog from './components/app/dialogs/ConfirmationDialog.vue';
import LoadingScreen from './components/app/dialogs/LoadingScreen.vue';

// import SnackBar from './components/Snackbar/SnackBar.vue',
export default {
  name: 'App',
  components: {
    SnackBar: () =>  import ("@/components/Snackbar/SnackBar"),
    ConfirmationDialog,
    LoadingScreen
  },
  data: () => ({
    //
  }),
  mounted() {
        // this.$vuetify.theme.dark = true
    },
};
</script>
<style scoped> 
@import url("../src/common/styles.css");
</style>